import * as React from "react";
import { graphql, HeadFC, Link, PageProps } from "gatsby";
import { Seo } from "components/Seo";
import { Layout } from "components/Layout";

const PostPage: React.FC<PageProps<Queries.PostQuery>> = ({ data }) => {
  return (
    <Layout>
      <h1>{data.microcmsPosts?.title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: `${data.microcmsPosts?.content}`,
        }}
      />
    </Layout>
  );
};

export const query = graphql`
  query Post($id: String!) {
    microcmsPosts(id: { eq: $id }) {
      postsId
      publishedAt
      title
      updatedAt
      content
      author
      category {
        name
        slug
      }
      book {
        afLink
        link
        category
        title
      }
    }
  }
`;

export default PostPage;

export const Head: HeadFC<Queries.PostQuery> = ({ data }) => (
  <Seo title={data.microcmsPosts?.title} />
);
